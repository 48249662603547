<template>
    <div class="page">
        <div class="earn-main earn-account-main">
            <div class="top earn-top-bg">
                <div class="back-btn">
                    <div class="arrow" @click="goBack">{{ $t('coin_treasure.return') }}</div>
                    <div class="history-btn" @click="goToHistory">{{ $t('coin_treasure.history') }}</div>
                </div>
                <div class="title earn-top-link">
                    <h2 class="h2">{{ $t('coin_treasure.currency_account') }}</h2>
                    <div class="history-btn" @click="goToHistory">{{ $t('coin_treasure.history') }}</div>
                </div>
                <div class="bottom">
                    <div class="card">
                        <div class="p">{{ $t('coin_treasure.holding_assets') }}</div>
                        <div class="total">≈ {{ amountValue }} USDT</div>
                        <!-- <div class="val up">≈ $ 1996.37</div> -->
                    </div>
                    <div class="card">
                        <div class="p">{{ $t('coin_treasure.cumulative_income') }}</div>
                        <div class="total">≈ {{ interestValue }} USDT</div>
                        <!-- <div class="val down">≈ $ 1996.37</div> -->
                    </div>
                    <div class="card">
                        <div class="p">{{ $t('coin_treasure.yesterday_earnings') }}</div>
                        <div class="total">≈ {{ lastInterestValue }} USDT</div>
                        <!-- <div class="val" :class="{{ value>0?'up':'down'}}">≈ $ {{value}}</div> -->
                        <!-- <div class="val" :class="'up'">≈ $ {{value}}</div> -->
                    </div>
                </div>
            </div>

            <div class="earn-table">
                <div class="tabs-con">
                    <div class="tab">
                        <div :class="{ active: selected_type === 1 }">
                            <a href="javascript:;" @click="selected_type = 1">{{ $t('coin_treasure.current') }}</a>
                        </div>
                        <div :class="{ active: selected_type === 2 }">
                            <a href="javascript:;" @click="selected_type = 2">{{ $t('coin_treasure.regular') }}</a>
                        </div>
                    </div>
                </div>

                <List v-if="selected_type == 1" :tableData='currentList' :type="selected_type" />
                <List v-else :tableData='regularList' />
                <ListMobile v-if="selected_type == 1" :tableData='currentList' :type="selected_type" />
                <ListMobile v-else :tableData='regularList' />
                <div class="loading-box" v-if="isLoadCurrentList">
                    <loading-indicator />
                </div>
                <div class="col" v-if="showNoData">
                    <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

// import Coin from '../Components/Coin.vue';
import List from './Components/List.vue';
import ListMobile from './Components/ListMobile.vue';
import { savingOrders } from './request'

export default {
    components: { List, ListMobile },
    data() {
        return {
            list: [],
            selected_type: 1,
            value: -1996.37,
            currentList: [],
            regularList: [],
            isLoadCurrentList: true,
            amountValue: '--',
            interestValue: '--',
            lastInterestValue: '--',

        };
    },
    created() {

    },
    computed: {
        showNoData() {
            if(this.selected_type == 1) {
                return !this.isLoadCurrentList && this.currentList.length == 0
            } else {
                return !this.isLoadCurrentList && this.regularList.length == 0
            }
        }
    },
    mounted() {
        this.getSavingOrder()
    },
    methods: {
        goBack() {
            window.history.go(-1)
        },
        goToHistory() {
            this.$router.push('/saving/history')
        },
        getSavingOrder() {
            savingOrders().then(res => {
                // 取消loading
                this.isLoadCurrentList = false

                if (res.data.errcode !== 0) {
                    return false
                }
                let data = res.data.data
                this.list = data
                this.formData(data)
                let amountValue = 0
                let interestValue = 0
                let lastInterestValue = 0
                data.forEach(item => {
                    if (item.status === 1 || item.status === 2) {
                        amountValue += item.amountValue
                    }
                    interestValue += item.interestValue
                    lastInterestValue += item.lastInterestValue
                })
                this.amountValue = amountValue.toFixed(4)
                this.interestValue = interestValue.toFixed(4)
                this.lastInterestValue = lastInterestValue
            })
        },
        formData(data) {
            //分成两组数组活期和定期
            let currentList = []
            let regularList = []
            data.forEach(item => {
                if (item.numDays === 0) {
                    currentList.push(item)
                } else {
                    regularList.push(item)
                }
            })
            this.currentList = currentList
            this.regularList = regularList

        },
        redeemDialog() {
            this.showDialog()
        },

        showDialog: function () {
            this.$refs.earnModal.showModal();
        }
    },
};
</script>
