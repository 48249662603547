<template>
    <div class="mod bg-content order-options">
        <div class="mod-head bg-title text-label d-flex justify-content-between">
            <div>{{ $t('boption.label_order_title') }}</div>
            <span aria-hidden="true" @click="onCloseOrderOptions">&times;</span>
        </div>
        <div class="mod-body text-content">
            <div class="row flex-column">
                <div class="col text-label">
                    {{ $t('boption.label_order_amount') }}
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-4" v-for="amt in boptionConfig.supportedAmounts" :key="amt">
                            <div class="inline-radio">
                                <input type="radio" name="amount" :id="'order_amount' + amt" :value="amt" v-model="boptionOrder.amount" />
                                <label :for="'order_amount' + amt">{{ formatAmount(amt) }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row flex-column">
                <div class="col text-label">{{ $t('boption.label_order_duration') }}</div>
                <div class="col">
                    <div class="row">
                        <div class="col-4" v-for="(val, index) in boptionConfig.durations" :key="index">
                            <div class="inline-radio">
                                <input type="radio" name="duration" :id="'order_duration' + val.duration" :value="val.duration" v-model="duration" />
                                <label :for="'order_duration' + val.duration">{{ val.duration }}s</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row flex-column">
                <div class="col text-label">{{ $t('boption.label_order_profit_rate') }}</div>
                <div class="col profit-rate color-down">{{ boptionOrder.profitRate }}</div>
            </div>

            <div class="row">
                <div class="col">
                    <button class="btn-submit bg-up" @click="triggerCreateOrder(true)">{{ $t('boption.label_buy_up') }} <i class="fa fa-arrow-up"></i></button>
                    <button class="btn-submit bg-down" @click="triggerCreateOrder(false)">{{ $t('boption.label_buy_down') }} <i class="fa fa-arrow-down"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['boptionConfig', 'boptionOrder'],

    data() {
        return {
            // The duration of a new order.
            duration: null
        };
    },

    created() {
        this.duration = this.boptionOrder.duration;
    },

    watch: {
        duration: function () {
            this.boptionOrder.changeDuration(this.duration);
        }
    },

    methods: {
        triggerCreateOrder: function (direction) {
            if (this.boptionOrder.useCoupons === false) {
                const amt = this.boptionOrder.amount * 1;
                if (!Number.isInteger(amt) || amt < 1) {
                    if (this.useCustomAmount) {
                        $(this.$refs.customAmountInput).focus();
                    }
                    return false;
                }
            }

            this.$emit('create-order', direction);
        },

        onCloseOrderOptions: function () {
            this.$emit('close-options');
        },

        formatAmount: function (amt) {
            if (amt >= 1000) {
                if (amt % 1000 === 0) {
                    return (amt / 1000).toLocaleString('en') + 'k';
                }
                return amt.toLocaleString('en');
            }
            return amt;
        }
    }
};
</script>
<style scoped>
.close {
    background: none;
}
@media (max-width: 768px) {
    .trade-layout .micro-trade .bg-title span {
        font-size: 2rem;
    }
}
</style>