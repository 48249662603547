<template>
    <form :class="{ 'd-none': !showInputs }" class="login-form" method="post" action="javascript:void(0);" @submit="createAccount">
        <h1>{{ $t('register.title') }}</h1>

        <div class="form-group">
            <template v-if="emailVCode">
                <!-- Email account -->
                <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
            </template>
            <template v-else>
            <!-- Phone account -->
                <label>{{ $t('user.label_account') }}</label>
                <div class="d-flex">
                    <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                    <div class="flex-fill">
                        
                        <input
                            v-model.trim="account"
                            name="Name"
                            type="text"
                            autocomplete="off"
                            pattern="\d*"
                            data-val="true"
                            data-val-required="*"
                            data-val-regex="*"
                            data-val-regex-pattern="^0?[1-9]\d+$"
                            class="form-control round-right"
                        />
                    </div>
                </div>
            </template>
        </div>
        <div class="form-group">
            <label>{{ $t('register.label_password') }}</label>
            <div class="password-box">
                <input
                    name="Password"
                    v-model.trim="password"
                    :type="showPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-maxlength="*"
                    data-val-maxlength-max="32"
                    data-val-minlength="*"
                    data-val-minlength-min="2"
                />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <label>{{ $t('register.label_confirm_password') }}</label>
            <div class="password-box">
                <input
                    name="ConfirmPassword"
                    v-model.trim="confirmPassword"
                    :type="showConfirmPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-equalto="*"
                    data-val-equalto-other="Password"
                    data-val-maxlength="*"
                    data-val-maxlength-max="32"
                    data-val-minlength="*"
                    data-val-minlength-min="6"
                />
                <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group" v-if="emailVCode">
            <div class="d-flex">
                <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                <div class="flex-fill">
                    <input v-model.trim="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                </div>
            </div>                
        </div>
        <div class="form-group">
            <label>{{ $t('register.label_referral_code') }}</label>
            <div class="code">
                <input
                    type="text"
                    name="ReferralCode"
                    v-model.trim="refCode"
                    autocomplete="off"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                />
            </div>
        </div>
        
        <!-- Do we need SMS verification? or just use a simple image verification code? -->
        <sms-verification v-if="config.signupVCodeRequired" :regionCode="regionCode" :emailVCode="emailVCode" :account="account" action="signup" />

        <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
            {{ $t('register.label_register') }}
        </button>
        <div class="text-center form-group">
            <router-link to="/user/login" class="help-link">{{ $t('register.label_login') }}</router-link>
        </div>
    </form>
</template>
<style scoped>
.page-top-nav{
    display: flex;
    margin-bottom: 2rem !important;
    align-items: center;
    justify-content: space-around;
}
.page-top-nav li{
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4.25rem;
    height: .125rem;
    background-color: #041438;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
#login-page button.btn-submit {
    margin: 1rem 0;
    display: block;
    width: 100%;
    padding: .75rem 0;
    color: #fff;
    background: #333333;
    border-radius: 6px 6px 6px 6px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
    font-size: 14px;
}
.form-group {
    margin-bottom: 1.5625rem;
}
.help-link {
    font-size: 12px;
    color: #7F8490;
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            password: null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,

            // Submit status
            submitting: false,
            completed: false,
            emailVCode:false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        },
        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        createAccount: function (e) {
            if(this.password != this.confirmPassword){
                $.top_error(this.$t('withdraw_password.err_different_passwords'));
                return
            }
            const frm = $(e.target);
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        // Save the current authenticated user.
                        setProfile(json.data);

                        // redirect to home page
                        self.completed = true;

                        setTimeout(function () {
                           //register jump to real-name page
                           self.$router.push('/user');
                        }, 500);
                    } else if(json.errcode === 103){
                        // 什么也不做，保持loading状态
                    } else {
                        self.submitting = false;
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    self.submitting = false;
                    $.top_error(self.$t('general.operation_error'));
                })
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>